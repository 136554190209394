/**
 * 从 VUE_APP_API_HOST 中提取 API 路径前缀
 *
 * @param {string} url 地址
 */
function extractAPIPathPrefix(url) {
    let host = new URL(url);
    let path = host.pathname

    if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
    }

    return path;
}

let apiurl = {
    merchant: {
        url: extractAPIPathPrefix(process.env.VUE_APP_API_HOST) ?? '/api/merchant',     // 具体地址
        needLogin: false,      // 是否需要登录
        needAuth: false,        // 是否需要授权认证
    },
}
export default apiurl
