import env from '@/../config/env'
// import auth from '@/../config/auth'      // 本地权限
import apiurl from '@/../config/apiurl'
import axios from 'axios'
import { Message, MessageBox } from 'element-ui';

const tool = {
    // 判断是否是空的
    is_empty: (data = '') => {
        // 判断空对象
        if (JSON.stringify(data) === '{}') return true
        // 判断空数组
        if (JSON.stringify(data) === '[]') return true
        // 判断空字符串
        if (data === '') return true
        // 判断undefined
        if (data === undefined) return true
        // 判断undefined
        if (data === null) return true
        return false
    },
    // 错误消息弹出
    errormes: (json, callbackfun) => {

        if (json.code === 10002) { // 普通提示
            Message({
                message: json.message,
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        } else if (json.code === 10005) {
            Message({
                message: json.message, type: 'warning',
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        } else if (json.code === 10000) {
            console.log(json)
            let message = '<p style="line-height: 20px;">'
            for (let val_tip of json.message_all) {
                message += `${val_tip}</br>`
            }
            message += '</p>'

            MessageBox.alert(message, '信息提醒', {
                confirmButtonText: '关闭',
                dangerouslyUseHTMLString: true,
                type: 'error',
                callback: action => {
                    callbackfun && callbackfun(action)
                }
            });

        } else {
            Message({
                message: json.message, type: 'error',
                duration: env.message_error,
                onClose: () => {
                    callbackfun && callbackfun()
                }
            });
        }
    },
    // POST请求
    post_data: (url, postData = {}, callbackfun) => {
        // 判断请求地址
        let qurl = env.API_URL

        if (apiurl[url] !== undefined) {
            qurl += apiurl[url].url
            // 判断是否需要登录
            if (apiurl[url].needLogin) {
                const token = tool.get_l_cache('token') ?? ''
                if (token == '') {
                    // 跳转到登录页面
                    console.log('请先登录')
                    Message.error({
                        message: '请先登录',
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                    return
                } else {
                    postData.token = token
                }
            }
            // 判断是否需要授权

        } else {
            if (url.substring(0, 4) === 'http') {
                qurl = url
            } else {
                qurl += '/' + url
            }
        }

        // DE_BUG 模式打印请求参数
        if (env.DE_BUG === 'true') {
            // console.log('******************************DE_BUG模式***POST请求*******************')
            // console.log('请求地址', qurl)
            // console.log('请求参数', postData)

        }
        axios.post(qurl, postData)
            .then(function (response) {
                if (env.DE_BUG === 'true') {
                    // console.log('返回参数body', response.data)
                    // console.log('***************************DE_BUG模式***POST请求***结束****************')
                }

                if (response.status === 200) {
                    // if (response.data.code === 10005) {
                    //     tool.del_l_cache('user')
                    //     location.href = '/'
                    //     return
                    // }
                    callbackfun && callbackfun(response.data)
                }
            })
            .catch(function (error) {
                console.log('系统错误', error)
                Message.error('系统错误：' + error.message);
            })
    },
    // GET请求
    get_data: (url, postData = {}, callbackfun) => {
        // 判断请求地址
        let qurl = env.API_URL

        if (apiurl[url] !== undefined) {
            qurl += apiurl[url].url
            // 判断是否需要登录
            if (apiurl[url].needLogin) {
                const token = tool.get_l_cache('token') ?? ''
                if (token == '') {
                    // 跳转到登录页面
                    console.log('请先登录')
                    Message.error({
                        message: '请先登录',
                        onClose: () => {
                            location.href = '/'
                        }
                    });
                    return
                } else {
                    postData.token = token
                }
            }
            // 判断是否需要授权

        } else {
            if (url.substring(0, 4) === 'http') {
                qurl = url
            } else {
                qurl += '/' + url
            }
        }

        // DE_BUG 模式打印请求参数
        if (env.DE_BUG === 'true') {
            console.log('******************************DE_BUG模式***GET请求*******************')
            console.log('请求地址', qurl)
            console.log('请求参数', postData)

        }
        axios.get(qurl, { params: postData })
            .then(function (response) {
                if (env.DE_BUG === 'true') {
                    console.log('返回参数body', response.data)
                    console.log('***************************DE_BUG模式***GET请求***结束****************')
                }
                if (response.status === 200) {
                    callbackfun && callbackfun(response.data)
                }
            })
            .catch(function (error) {
                console.log('系统错误', error)
                Message.error('系统错误：' + error.message);
            })
    },

    // 缓存处理
    set_s_cache: (key, val,) => {
        return sessionStorage.setItem(key, JSON.stringify(val))
    },
    get_s_cache: key => {
        return JSON.parse(sessionStorage.getItem(key))
    },
    del_s_cache: key => {
        if (key === 'all') {
            return sessionStorage.clear()
        } else {
            return sessionStorage.removeItem(key)
        }
    },
    set_l_cache: (key, val) => {
        return localStorage.setItem(key, JSON.stringify(val))
    },
    get_l_cache: key => {
        return JSON.parse(localStorage.getItem(key))
    },
    del_l_cache: key => {
        if (key === 'all') {
            return localStorage.clear()
        } else {
            return localStorage.removeItem(key)
        }
    },
    set_c_cache: (key, val, time = 0) => {
        // 设置cookie方法
        let date = new Date()
        let expiresDays = time
        date.setTime(date.getTime() + expiresDays * 1000)
        document.cookie = key + '=' + val + ';expires=' + date.toGMTString()
    },
    get_c_cache: key => {
        let getCookie = document.cookie.replace(/[ ]/g, '')
        let arrCookie = getCookie.split(';')
        let value
        for (let i = 0; i < arrCookie.length; i++) {
            let arr = arrCookie[i].split('=')
            if (key === arr[0]) {
                value = arr[1]
                break
            }
        }
        return value
    },
    del_c_cache: key => {
        if (key === 'all') {
            let keys = document.cookie.match(/[^ =;]+(?==)/g)
            if (keys) {
                for (let i = keys.length; i--;) {
                    document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
                }
            }
        } else {
            let date = new Date()
            date.setTime(date.getTime() - 10000)
            document.cookie = key + '=v; expires =' + date.toGMTString()
        }
    },
    // 判断权限
    is_auth: val => {
        // 用户权限
        let data = tool.get_l_cache('userauth')

        if (data.includes(val)) {
            return true
        } else {
            return false
        }
    },
    // 加
    bcadd(arg1, arg2) {
        let r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return Number((arg1 * m + arg2 * m) / m)
    },
    // 减
    bcsub(arg1 = '', arg2 = '') {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //last modify by deeka
        //动态控制精度长度
        n = (r1 >= r2) ? r1 : r2;
        return Number(((arg1 * m - arg2 * m) / m).toFixed(n))
    },
    // 乘
    bcmul(arg1 = 0, arg2 = 1) {
        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            if (!tool.is_empty(s1.split(".")[1])) {
                m += s1.split(".")[1].length
            } else {
                m += 0
            }
        } catch (e) {
            console.log(e)
        }
        try {
            if (!tool.is_empty(s2.split(".")[1])) {
                m += s2.split(".")[1].length
            } else {
                m += 0
            }
        } catch (e) {
            console.log(e)
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    },
    // 时间判断
    time_decide(time, decide = '<', time2 = 'now') {
        let date1 = new Date(time).getTime()        // 判断时间
        let date2 = new Date().getTime()            // 当前时间
        if (time2 !== 'now') {
            date2 = new Date(time2).getTime()       // 判断时间2
        }

        if (decide === '>') {
            if (date1 > date2) {
                return true
            } else {
                return false
            }
        } else {
            if (date1 < date2) {
                return true
            } else {
                return false
            }
        }
    },
    isFloatString(value) {
        // 先将字符串转换为数字类型，并检查是否为有效数字
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
            return false;
        }
        if (typeof value === 'number') value = value.toString();
        // 判断字符串是否包含小数点
        if (value.includes('.')) {
            return true;
        }
        return false;
    }
}

export default tool
